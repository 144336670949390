<template>
  <div class="chat-item-container">
    <div class="chat-item">
        <div class="chat-title-and-time">
            <p class="chat-title">天榜号客服10001</p>
            <p class="chat-time">2023-10-30 23:18:36</p>
        </div>
        <div class="chat-content">
            <p>您好，感谢您关注【天榜号】！自2019年起，我们先后成立了游戏玩家社群，做游戏账号交易担保平台。截止目前，我们为广大游戏爱好者提供担保服务已有5年，并且一直潜心研发及倾心服务只为买卖双方更好的体验！如遇客服不在线或者服务繁忙，可留下您的联系方式，我们将竭诚为您服务！</p>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.chat-item-container{
    width: 530px;//最大为width: 545px;
    height: 420px;
    margin-top: 20px;
    background-color: #ffffff;
    
    .chat-item{
        width: 500px;
        display: flex;
        flex-direction: column;
        
        .chat-title-and-time{
            display: flex;
            // background-color: pink;
            background-color: #ffffff;
            .chat-title{
                font-size: 12px;
                color: rgb(165, 165, 165);
            }
            .chat-time{
                margin-left: 5px;
                font-size: 12px;
                color: rgb(165, 165, 165);
            }
        }
        .chat-content{
            margin-top: 3px;
            padding: 15px 10px;
            font-size: 14px;
            background-color: rgb(239, 241, 246);
            border-top-right-radius: 8px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
}
</style>