<template>
  <div class="container">
    <div class="content">
        <div class="top">
            <div class="left">
                <p class="top-title">天榜号客服正在为您服务</p>
                <p class="top-number">客服编号：10001</p>
            </div>
        </div>
        <div class="chat-content">
            <div class="left">
                <!-- <div class="chat-windown infinite-list-wrapper" style="overflow:auto">
                        <p v-if="noMore">没有更多了</p>
                        <p v-if="loading">加载中...</p>
                        <ul
                            class="list"
                            
                            v-infinite-scroll-top="loadTop"
                            infinite-scroll-disabled="disabled">
                            <li v-for="i in count" :key="i" class="list-item">{{ i }}</li>
                            <li>
                                <chat-item></chat-item>
                            </li>
                        </ul>
                </div> -->

                <div class="chat-windown" @scroll="handleScroll" ref="scrollContainer" style="overflow:auto">
                        <div class="chat-windown-time">
                            <p class="chat-start-time">{{currentTime}} 开始沟通</p>
                        </div>
                        <!-- <p v-if="loading">加载中...</p> -->
                        <chat-item></chat-item>
                    <!-- <ul>
                    <li v-for="item in list" :key="item.id">{{ item.name }}</li>
                    </ul> -->
                </div>
                <div class="chat-emoji">
                    <div class="chat-emoji-left">
                        <ul>
                            <li>
                                <el-tooltip class="item" effect="dark" content="表情" placement="top">
                                    <el-button class="tool-bar-item-face"></el-button>
                                </el-tooltip>
                            </li>
                            <li>
                                <el-tooltip class="item" effect="dark" content="图片" placement="top">
                                    <el-button class="tool-bar-item-picture"></el-button>
                                </el-tooltip>
                            </li>
                            <li>
                                <el-tooltip class="item" effect="dark" content="文件" placement="top">
                                    <el-button class="tool-bar-item-file"></el-button>
                                </el-tooltip>
                            </li>
                            <li>
                                <el-tooltip class="item" effect="dark" content="震动" placement="top">
                                    <el-button class="tool-bar-item-shake"></el-button>
                                </el-tooltip>
                            </li>
                        </ul>
                    </div>
                    <div class="chat-emoji-right">
                        <input class="chat-emoji-right-input" type="text" placeholder="请输入电话号码">
                        <div class="chat-emoji-right-phone">
                            <p>电话联系</p>
                        </div>
                    </div>
                </div>
                <div class="input-windown">
                    <textarea  class="input-windown-textarea"></textarea>
                </div>
                <div class="bottom">
                    <div class="copyright"><span>天榜号技术部提供技术支持</span></div>
                    <p class="bottom-send-btn"><span>发送</span></p>
                </div>
            </div>
            <div class="right">
                <video controls src="../../../public/images/chat/video_ad.mp4" autoplay poster="../../../public/images/chat/video_cover.png"></video>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import ChatItem from './component/chat-item.vue';
import moment from 'moment'
export default {
    components:{
        ChatItem
    },
data() {
    return {
      list: [], // 数据列表
      currentPage: 1, // 当前页数
      pageSize: 10, // 每页数据数量,
      loading:false,
      noMore:false,
      totalPages: 2,
    };
  },
  mounted() {
    // 初始化加载数据
    this.loadData();
  },
  methods: {
    handleScroll(event) {
      const container = event.target;
      if (event.target.scrollTop === 0) {
        // 滚动条滚动到顶部时，加载更多数据
        console.log('触发加载数据函数')
        this.loadMoreData();
      }
    },
    loadData() {
        // 加载初始数据
        this.list=[
            {id:1,name:1},{id:2,name:1},{id:3,name:1},{id:4,name:1},{id:5,name:1},
            {id:6,name:1},{id:7,name:1},{id:8,name:1},{id:9,name:1},{id:10,name:1},
            {id:11,name:1},{id:12,name:1},{id:13,name:1},{id:14,name:1},{id:15,name:1},
            {id:16,name:16},{id:17,name:17},{id:18,name:18},{id:19,name:19},{id:20,name:20}
        ]
        // 加载数据完成后，滑动至最底部
        this.$nextTick(() => {
            const container = this.$refs.scrollContainer;
            container.scrollTop = container.scrollHeight;
        });
    },
    loadMoreData() {
        if(this.loading||this.noMore){
            return;
        }
        this.loading=true;
        setTimeout(()=>{
            // 发起请求获取数据
            this.list.unshift(
                {id:21,name:21},{id:22,name:21},{id:23,name:21},{id:24,name:21},{id:25,name:21},
                {id:26,name:21},{id:27,name:21},{id:28,name:21},{id:29,name:21},{id:30,name:21},
            )

            this.loading=false;
            this.currentPage++;
            if(this.currentPage==this.totalPages){
                this.noMore=true       
            }

        },2000);
        // 加载更多数据
        // 更新页数
    },
  },
    computed:{
        currentTime(){
            return moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        }
    }
}
</script>

<style lang="less" scoped>
.container{
    width: 100vw;
    height: 100vh;
    background-color: white;
    position: relative;
    background-image: url(../../../public/images/chat/bg_index.png);
    background-size: 100%;
    background-repeat: no-repeat;
    .content{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 820px;
        height: 670px;
        margin: center;
        //background-color: #c02323;
        border-radius: 8px;
        // 顶部
        .top{
            width: 820px;
            height: 60px;
            padding: 10px 20px;
            background-color: rgb(51, 51, 51);
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            .left{
                .top-title{
                    font-size: 14px;
                    color: #ffffff;
                }
                .top-number{
                    margin-top: 5px;
                    font-size: 12px;
                    color: #ffffff;
                }
            }
        }
        //聊天部分
        .chat-content{
            width: 820px;
            height: 610px;
            -webkit-box-shadow:0 0 10px rgba(0, 204, 204, .5);
            -moz-box-shadow:0 0 10px rgba(0, 204, 204, .5);
            box-shadow:0 0 10px rgba(0, 204, 204, .5);
            display: flex;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            background-color: #ffffff;
            //左边聊天内容
            .left{
                width: 570px;
                height: 610px;
                display: flex;
                border-right: 1px solid rgb(236, 238, 244);
                flex-direction: column;
                border-bottom-left-radius: 8px;

                //聊天窗口
                .chat-windown{
                    padding: 0 10px;
                    width: 570px;
                    height: 425px;
                    //background-color: #000000;
                    border-bottom: 1px solid rgb(236, 238, 244);
                    .chat-windown-time{
                        margin-top: 20px;
                        width: 530px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .chat-start-time{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 200px;
                            height: 30px;
                            background-color: rgb(245, 247, 250);
                            font-size: 12px;
                            border-radius: 4px;
                            color: rgb(97, 99, 103);
                        }
                    }

                }
                .chat-emoji{
                    width: 570px;
                    height: 32px;
                    // background-color: pink;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .chat-emoji-left{
                        ul{
                            display: flex;
                            align-items: center;
                            margin-left: 2px;
                            li{
                                margin: 0 10px;
                                width: 20px;
                                height: 28px;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                .tool-bar-item-face{
                                    padding: 0px;
                                    width: 20px;
                                    height: 20px;
                                    background-image: url("../../../public/images/chat/toolbar_emoij.svg");
                                    background-size: 20px 20px;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    border: none;
                                    background-color: #ffffff;
                                    &:hover,&:focus{
                                        background-image: url("../../../public/images/chat/toolbar_emoij_hover.svg");
                                    }
                                }
                                .tool-bar-item-picture{
                                    padding: 0px;
                                    width: 20px;
                                    height: 20px;
                                    background-image: url("../../../public/images/chat/toolbar_photo.svg");
                                    background-size: 20px 20px;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    border: none;
                                    background-color: #ffffff;
                                    &:hover,&:focus{
                                        background-image: url("../../../public/images/chat/toolbar_photo_hover.svg");
                                    }
                                }
                                .tool-bar-item-file{
                                    padding: 0px;
                                    width: 20px;
                                    height: 20px;
                                    background-image: url("../../../public/images/chat/toolbar_file.svg");
                                    background-size: 20px 20px;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    border: none;
                                    background-color: #ffffff;
                                    &:hover,&:focus{
                                        background-image: url("../../../public/images/chat/toolbar_file_hover.svg");
                                    }
                                }
                                .tool-bar-item-shake{
                                    padding: 0px;
                                    width: 20px;
                                    height: 20px;
                                    background-image: url("../../../public/images/chat/toolbar_shake.svg");
                                    background-size: 20px 20px;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    border: none;
                                    background-color: #ffffff;
                                    &:hover,&:focus{
                                        background-image: url("../../../public/images/chat/toolbar_shake_hover.svg");
                                    }
                                }
                           }
                        }
                    }
                    .chat-emoji-right{
                        margin-right: 15px;
                        width: 175px;
                        height: 100%;

                        // background-color: skyblue;
                        display: flex;
                        align-items: center;
                        position: relative;
                        .chat-emoji-right-input{
                            padding: 5px;
                            width: 175px;
                            height: 26px;
                            border-radius: 12px;
                            font-size: 12px;
                            border: 1px solid rgb(236, 238, 244);
                            outline-style: none;
                        }

                        .chat-emoji-right-phone{
                            position: absolute;
                            top: 3px;
                            right:0px;
                            width: 60px;
                            height: 26px;
                            background-color: rgb(51, 51, 51);
                            border-top-right-radius: 12px;
                            border-bottom-right-radius: 12px;
                            font-size: 12px;
                            font-weight: 700;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #ffffff;
                            cursor: pointer;
                        }

                    }
                }
                //输入窗口
                .input-windown{
                    width: 570px;
                    height: 93px;
                    background-color: #ffffff;
                    .input-windown-textarea{
                        resize: none;
                        padding: 10px;
                        width: 100%;
                        height: 93px;
                        border: none;
                        outline-style: none;
                    }
                }
                //底部
                .bottom{
                    width: 570px;
                    height: 60px;
                    // background-color: pink;
                    border-bottom-left-radius: 8px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    .copyright{
                        width: 493px;
                        height: 32px;
                        display: flex;
                        justify-content: center;
                        align-items: flex-end;
                        cursor: default;
                        span{
                            font-size: 12px;
                        }
                    }
                    
                    .bottom-send-btn{
                        margin-right: 15px;
                        width: 62px;
                        height: 32px;
                        background-color: rgb(51, 51, 51);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 4px;
                        cursor: pointer;
                        span{
                            font-size: 12px;
                            color: #ffffff;
                            font-weight: 700;
                        }
                    }

                }
            }
            //右边品牌展示
            .right{
                width: 250px;
                height: 610px;
                border-left: 1px solid rgb(236, 238, 244);
                background-color: #ffffff;
                border-bottom-right-radius: 8px;
                video{
                    width: 249px;
                }
            }
        }
    }
}
</style>